<template>
  <article>
    <!-- Filtro Tabela -->
    <v-dialog v-model="modal.filtro" persistent>
      <v-card outlined>
        <v-card-title class="pt-6 mb-2" style="font-weight: normal;">
          <v-icon class="mr-2">fas fa-filter</v-icon>
          Filtros - Tabela XML
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4" md="3">
              <v-text-field clearable outlined append-icon="fas fa-file-alt" name="nome" label="Nome do arquivo"
              autocomplete="off" v-model="filtros.nome_arquivo" color="#018656"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <v-text-field clearable outlined append-icon="fas fa-hashtag" name="nfe" label="NFE"
              autocomplete="off" v-model="filtros.nfe" color="#018656"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <v-text-field clearable outlined append-icon="fas fa-calendar-alt" name="data_upload"
              autocomplete="off" :value="filtros.data_upload_inicial != '' &&
              filtros.data_upload_final != '' ?
              'De ' + formatarData(filtros.data_upload_inicial) +
              ' até '
              + formatarData(filtros.data_upload_final) : ''"
              label="Data do Upload" @click="() => filtros.exibirData = true" color="#018656"></v-text-field>

              <v-dialog v-model="filtros.exibirData" width="fit-content">
                <v-card outlined style="max-width: 800px; height: 100%; max-height: 540px;">
                  <v-card-text class="mt-6">
                    <v-card>
                      <v-card-text style="padding-top: 10px;">
                        <v-row dense>
                          <v-col>
                            <h1
                              style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                              Data Inicial
                            </h1>
                            <v-date-picker color="#018656" v-model="filtros.data_upload_inicial" size="100" width="100%">
                            </v-date-picker>
                          </v-col>

                          <v-col>
                            <h1
                              style="font-size: 1rem; text-align: center; margin-bottom: 5px; color: black; font-weight: normal;">
                              Data final
                            </h1>
                            <v-date-picker color="#018656" v-model="filtros.data_upload_final" size="100" width="100%">
                            </v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-row style="margin-top: 10px;" dense justify="end">
                      <v-col cols="12" sm="6">
                        <v-btn color="#018656" style="color: #FFFFFF; width:100%; margin-bottom: 10px;"
                          @click="() => filtros.exibirData = false">
                          CONFIRMAR
                        </v-btn>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-btn color="#FF5252" outlined style="color: #FF5252; width:100%; margin-bottom: 10px;"
                          @click="() => cancelarData()">
                          CANCELAR E REMOVER
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <v-select clearable outlined append-icon="fas fa-file-import" name="importado" label="Importado"
              autocomplete="off" v-model="filtros.importado" :items="importados_opcoes" 
              item-text="importado" color="#018656"></v-select>
            </v-col>
          </v-row>

          <v-row justify="center" dense width="100%">
            <v-col cols="12" sm="4" md="3">
              <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 50px;"
                @click="() => salvarFiltro()">
                <v-icon dense class="mr-2">fas fa-filter</v-icon>
                FILTRAR
              </v-btn>
            </v-col>

            <v-col cols="12" sm="4" md="3">
              <v-btn type="button" outlined style="color: #FF5252; width: 100%; height: 50px;"
                @click="() => cancelarFiltro()">
                <v-icon dense class="mr-2">fas fa-times</v-icon>
                FECHAR
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" sm="4" md="3">
              <v-btn type="button" color="#FF5252" style="color: #FFFFFF; width: 100%; height: 50px;"
                @click="() => limparFiltro()">
                <v-icon dense class="mr-2">fas fa-broom</v-icon>
                LIMPAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Inicio Loader carregamento -->
    <v-dialog v-model="loadingCarregamento" width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding: 20px !important;">
          Subindo os arquivos...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader carregamento -->

    <!-- Inicio Dialog de Erro no carregamento dos arquivos -->
    <v-dialog v-model="fileError" content-class="overflow-hidden" width="400" persistent>
      <v-card color="error" dark>
        <v-row class="pa-4" align="center" justify="center">
          <v-card-text>
            <v-icon dense class="pr-2">fas fa-exclamation-triangle</v-icon>
            Erro no carregamento dos arquivos.
            <!-- <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear> -->
          </v-card-text>
        </v-row>

        <v-row dense class="pl-4 pb-2">
          <v-spacer></v-spacer>
          <v-btn @click="fileError = false, uploadedFiles = []" text dark>
            Fechar
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- Fim -->

      <!-- Início Upload -->
      <v-card outlined>
        <div>
          <v-card-text style="font-weight: bolder; color: #7D7463; position: relative; top: 0.75em;"
            class="mb-0 ml-10">Insira o arquivo XML ou arquivo compactado(todas as notas XML):
          </v-card-text>
          <v-row dense class="pr-4">
            <!-- Upload Arquivo -->
            <v-col cols="6">
              <v-file-input hide-details="auto" class="ml-4 mt-0 mb-0" background-color="#E3E1D9" outlined dense @click:append="showFile = !showFile" :counter="20"
              label="Selecione o arquivo" @click="erro = []" v-model="uploadedFiles" multiple append-icon="fas fa-angle-down"></v-file-input>

              <v-card-text v-if="uploadedFiles.length > 0 && showFile == true" class="pt-0 ml-8 pb-0">
                <v-card outlined>
                  <v-virtual-scroll dense :items="uploadedFiles" height="160" item-height="50">
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item.name">
                        <v-list-item-content>
                          <div>
                            {{ item.name }}
                            <span class="ml-3 text--secondary">
                              {{ item.size }} bytes</span
                            >
                          </div>
                        </v-list-item-content>

                        <v-list-item-action class="d-flex" style="flex-direction: row;">
                          <div v-if="erro != []" v-for="err in erro">
                            <v-tooltip top v-if="err.nome == item.name">
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                  <v-icon color="warning" small>fas fa-exclamation-triangle</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ err.msg }}</span>
                            </v-tooltip>
                          </div>

                          <v-btn v-if="notErro == true || notErro == false && !index.includes(item.name)" icon>
                            <v-icon color="success" small>fas fa-check</v-icon>
                          </v-btn>

                          <v-btn @click.stop="removeFile(item.name)" icon>
                            <v-icon color="#E72929" small>fas fa-times-circle</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>

                      <v-divider></v-divider>
                    </template>
                  </v-virtual-scroll>
                </v-card>
              </v-card-text>
            </v-col>

            <!-- Botão Salvar -->
            <v-col v-if="uploadedFiles != null" cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" @click="saveFile()" class="mt-2 btnSave" icon dense>
                    <v-icon dense>fas fa-save</v-icon>
                  </v-btn>
                </template>
                <span>Salvar</span>
              </v-tooltip>
            </v-col>

            <v-spacer></v-spacer>

            <!-- Filtro -->
            <v-col cols="12" sm="4" md="3" style="height: 60px;">
              <v-btn type="button" color="#018656" style="color: #FFFFFF; width: 100%; height: 40px;"
                @click="() => modal.filtro = true">
                <v-icon dense class="mx-2">fas fa-filter</v-icon>
                FILTRAR
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>


      <!-- Início Tabela -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title>
          <v-text-field class="mx-4" color="#018656" prepend-icon="fas fa-search"
            label="Pesquisa rápida" single-line hide-details v-model="grid.arquivo.pesquisa">
          </v-text-field>
        </v-card-title>
        <v-card-text>
          <v-row :style="uploadedFiles.length > 0 && showFile ? 'height: calc(100vh - 525px);' : 'height: calc(100vh - 362px);'" class="overflow-y-auto">
            <v-col class="px-6 py-6">
              <v-data-table color="#018656" style="color: black;" dense :headers="grid.arquivo.headers"
              :items="grid.arquivo.items" :loading="grid.arquivo.carregando" hide-default-footer
              class="elevation-1 pt-4" :items-per-page="grid.arquivo.porPagina" fixed-header :search="grid.arquivo.pesquisa">

                <template v-for="header in grid.arquivo.headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
                  {{ header.formatter(value) }}
                </template>

                <template v-slot:item.pedidos_importados="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-app-bar-nav-icon v-on="on" >
                        <v-icon size="small" :color="getColor(item.pedidos_importados)">fa-solid fa-circle</v-icon >
                      </v-app-bar-nav-icon>
                    </template>
                    <span v-if="item.pedidos_importados == 1">Importado</span>
                    <span v-else>Não Importado</span>
                  </v-tooltip>
                </template>

              </v-data-table>
            </v-col>
          </v-row>

          <v-row style="margin-top: 15px; position: relative;">
            <v-col cols="8" style="max-height: 65px; position: absolute; left: 0; right: 0; margin: auto;">
              <v-pagination color="#018656" style="color: #018656" v-model="grid.arquivo.paginaAtual" @input="mudarPagina"
              :length="Math.ceil(grid.arquivo.totalRegistros / grid.arquivo.porPagina)" :total-visible="5"></v-pagination>
            </v-col>

            <v-spacer></v-spacer>

            <v-col class="d-none d-md-flex" cols="2" style="max-height: 65px;">
              <v-select color="#018656" dense outlined label="Resultados por página" :items="grid.arquivo.qtdsPaginacao"
                item-text="text" item-value="value" v-model="grid.arquivo.porPagina"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </article>
</template>

<script crossorigin="anonymous">
import { baseApiUrl, showSuccess } from '@/global'
import axios from 'axios'
import moment from "moment";

export default {
  name: 'UploadNfe',
  data: () => ({
    uploadedFiles: [],
    showFile: false,
    erro: [],
    notErro: null,
    index: [],
    modal: {
      filtro: false,
    },
    loadingCarregamento: false,
    fileError: false,
    filtros: {
      nome_arquivo: null,
      nfe: null,
      data_upload_inicial: "",
      data_upload_final: "",
      importado: null,
      exibirData: false,
    },
    grid: {
      arquivo: {
        headers: [
          { text: 'Nome Arquivo', value: 'nome_arquivo' },
          { text: 'NFE', value: 'chave_nfe' },
          { text: 'Data Upload', value: 'cadastrado_em', formatter: (x) => (x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null), },
          { text: 'Importado', value: 'pedidos_importados'},
        ],
        items: [],
        paginaAtual: 1,
        qtdsPaginacao: [10, 25, 50, 100],
        porPagina: 25,
        totalRegistros: 0,
        pesquisa: '',
        carregando: false,
      }
    },
    importados_opcoes: ["Sim", "Não"],
  }),

  created(){
    this.carregarXML();
  },

  methods: {
    async carregarXML(){
      this.grid.arquivo.carregando = true;
      let url = `${baseApiUrl}/list-xml?page=${this.grid.arquivo.paginaAtual}&por_pagina=${this.grid.arquivo.porPagina}`;

      await axios
        .get(url)
        .then((res) => {
          this.grid.arquivo.items = res.data.dados.data;
          this.grid.arquivo.totalRegistros = res.data.dados.total;
        })
        .finally(() => (this.grid.arquivo.carregando = false));
    },

    mudarPagina(pagina){
      this.grid.arquivo.paginaAtual = pagina;
      this.carregarXML();
    },

    /** Função que faz o upload do arquivo quando clica no botão de salvar */
    saveFile() {
      this.loadingCarregamento = true;
      this.erro = [];
      this.index = [];
      let config = { header : { 'Content-Type' : 'multipart/form-data' } };
      const data = new FormData();

      for(let file in this.uploadedFiles)
        data.append('arquivo[]', this.uploadedFiles[file]);

      let url = `${baseApiUrl}/upload-xml`;

      axios
      .post(url, data, config)
        .then((res) => {
          if(res.data.erros){
            for(let error in res.data.erros){
              this.erro.push({
                nome: res.data.erros[error].arquivo,
                msg: res.data.erros[error].erro
              });
            }
            for(let error2 in this.erro){
              this.index.push(this.erro[error2].nome);
            }
          } else{
            this.notErro = true;
          }
          
          showSuccess(res.data.mensagem);
        })
        .catch(function (error) {
          if (error.response) {
            this.fileError = true;
          }
        })
        .finally(() => (this.uploadedFiles = [], this.loadingCarregamento = false, this.carregarXML()));
    },

    /** Função para remover um arquivo específico da lista de arquivos */
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        file => file.name === fileName
      );
      // Se estiver entre os arquivos enviados, faz a remoção
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    
    salvarFiltro(){
      this.modal.filtro = false;
      this.carregarXML();
    },
    cancelarFiltro(){
      this.modal.filtro = false;
    },
    limparFiltro(){
      this.filtros.nome_arquivo = null;
      this.filtros.nfe = null;
      this.filtros.data_upload_inicial = "";
      this.filtros.data_upload_final = "";
      this.filtros.importado = null;
    },

    cancelarData() {
      this.filtros.data_upload_inicial = '';
      this.filtros.data_upload_final = '';
      this.filtros.exibirData = false;
    },

    /** Formata a data para o padrão brasileiro */
    formatarData(data) {
      return moment(data).format('DD/MM/YYYY');
    },

    /** Configura a cor do botão 'Importado' */
    getColor(item){
      if(item == 0)
        return "red";
      if (item == 1){
        return "green";
      }
    }
  }
};
</script>

<style>
 .btnSave:hover{
  color: #1976d2 !important;
}
</style>